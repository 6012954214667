// syntax highlight by rouge
// list of tokens: https://github.com/rouge-ruby/rouge/wiki/List-of-tokens

.highlight {
  overflow: hidden;
  word-wrap: break-word;
  color: $code-primary;
  font-family: $font-monospace;
  font-size: 86%;
  line-height: 1.3;
  padding: 5px 5px 7px 5px;
  .w /* specially highlighted whitespace */ {
    color: rgba($code-background, 0.6);
  }
  .err /* Error */ {
    color: $error;
    background-color: inherit;
  }
  .x /* Other. Token for data not matched by a parser */ {
    color: $code-primary;
  }
  // Keyword
  .k, /* Keyword */
  .kd, /* Declaration */
  .kp /* Pseudo. Keywords that aren't really keywords */ {
    color: $keyword;
    font-weight: bold;
  }
  .kc /* Constant */ {
    color: $global;
    font-weight: bold;
  }
  .kn /* Namespace */ {
    color: $namespace;
  }
  .kr /* Reserved */ {
    color: $keyword;
    font-style: italic;
  }
  .kt/* Type */ {
    color: $type;
    font-weight: bold;
  }
  // Name
  .n /* Variable/function names */
  .na, /* Attribute */
  .py, /* Property */
  .nl, /* Label */
  .nx, /* Other */
  .nt, /* Tag */
  .nv, /* Variable */
  .vc, /*  Variable.class */
  .vg, /* Variable.Global */
  .vi /* Variable.Instance */ {
    color: $variable;
  }
  .nb,/* Builtin */
  .bp/* Builtin.Pseudo */ {
    color: $built-in;
  }
  .nc /* Class */ {
    color: $class;
    font-weight: bold;
  }
  .no/* Constant */ {
    color: $global;
    font-weight: bold;
  }
  .nd /* Decorator */ {
    color: $built-in;
    font-weight: bold;
  }
  .nf /* Funtion */
  .ni /* Entity. Token for entitites such as &nbsp; in HTML */ {
    color: $code-primary;
  }
  .ne /* Exception */ {
    color: $error;
  }
  .nn /* Namespace */ {
    color: $namespace;
  }
  // Literal
  .l /* Any literal */ {
    color: $string;
  }
  // Literal.String
  .s, 
  .s1, /* Single */
  .sb, /* Backtick */
  .sd, /* Doc */
  .s2, /* Double */
  .sh, /* Heredoc */
  .sx  /* Other */ {
    color: $string;
  }
  .sr, /* Regex */
  .sc, /* Char */ 
  .ss, /* Symbol */ 
  .si, /* Interpol */
  .se /* Escape */ {
    color: $string;
    font-weight: bold;
  }
  // Literal.Number
  .m, /* Float */
  .mf, /* Hex */
  .mh, /* Integer */
  .mi, /* Integer.Long */
  .il, /* Oct */
  .mo, /* Hex */
  .mx, /* Binary literals */
  .mb {
    color: $number;
}
  .ld /* Literal.Date */ {
    color: $number;
    font-style: italic;
  }
  // Operator and Punctuation
  .o, /* Operator */
  .ow, /* Operator.Word */
  .p /* Punctuation */ {
    color: $punctuation;
  }
  // Comment
  .c,
  .cm, /* Multiline */
  .cs /* Special */ {
    color: $comment;
  }
  .cp /* Preproc */ {
    color: $comment;
    font-weight: bold;
    font-style: italic;
  }
  .c1 /* Single */ {
    color: $comment;
    font-style: italic;
  }
  // Generic - Unstyled token
  .gd /* Deleted */ {
    color: $built-in;
  }
  .gi /* Inserted */ {
    color: $string;
  }
  .ge /* Emph */ {
    color: $code-primary;
    font-style: italic;
  }
  .gr /* Error */
  {
      color: $error;
      background-color: $error-background;
  }
  .gs /* Strong */ {
    color: $code-primary;    
    font-weight: bold;
  }
  .go /* Output */ {
    background-color: $error-background;    
  }
  .gt /* Trackback */
   {
    color: $string;
    font-weight: bold;
    background-color: $error-background;    
  }
  .gl /* Lineno */ {
    color: $code-primary;
    background: $error-background;
  }
  .gp, /* Prompt */
  .gh, /* Heading */
  .gu /* Subheading */ {
    color: $code-primary;
    font-weight: bold;
  }
}
.highlight table {
    margin-bottom: 0;
    font-size: 1em;
    border: 0;
    td {
      padding: 0;
      width: calc(100% - 1em);
      border: 0;
      /* line numbers*/
      &.gutter,
      &.rouge-gutter {
        padding-right: 1em;
        width: 1em;
        color: $code-primary;
        border-right: $medium-border;
        text-align: right;
      }
      /* code */
      &.code,
      &.rouge-code {
        padding-left: 1em;
      }
    }
    pre {
      margin: 0;
    }
  }
.highlight pre {
  background-color: $code-background;  
  overflow-x: auto;
  @include thin_scrollbar; 
  // max-width: 99%;
  }
.highlight .hll {
    background-color: $code-background;
  }