// table of content
.popup_btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.div_toc {
  position: absolute;
  bottom: auto;
  top: $toc-position + 0.6px;
  right: -0.5px;  
  z-index: 2;
  max-width: 70%;
  max-height: 85%;
  width: auto;
  overflow-x: hidden;
  @include thin_scrollbar;
  visibility: hidden;
  background-color: rgba($color-postbg, 0.85);
  text-align: left;
  padding: 0px 0;
  border-left: $medium-border;
  border-bottom: $medium-border;
  ul {
    padding: 0 4px 0 18px;
    margin: 5px 4px;
    li a {
      font-size: 16px;
      color: $color-primary;
      text-decoration: none;
    }
  }
}


