.post_info {
  text-align: right;
  padding: 0 50px;
  font-size: $font-smaller;
  margin: 0;
}
.abstract {
  font-size: $font-smaller;
  color: rgba($color-primary, 0.9);
  padding: 1.1em 2.8em;
  p {
    border-bottom: $thin-border;
  }
}
