.page {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  // text-align: center;
  background-color: $color-bg;
  background-image: $image-background;
}

.submit_dbtn {
  height:32px;
  width:200px;
  background-color:darkslategrey;
}

.submit_dbtn:hover {
  background-color:transparent;
}

.container {
  top: 2%;
  height: 97%;
  width: 84%;
  max-width: 1350px;
  position: relative;
  overflow: none;
  margin: 0 auto;
  box-sizing: border-box; // interior border
  background-color: rgba($color-postbg, 0.72);
}
.div_article {
  top: $header-height + 1px;
  height: 95%;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  @include thin_scrollbar;
  border: $secondary-border;
  border-top: none;
  article {
    margin: 0;
    padding: 10px 19px 19px 19px;
    text-align: left !important;
    min-height: 88%; 
  }
  td {
    border: $thin-border;  
  }
  .encrypted {
    color: rgba($color-warning, 0.9);
    word-break: break-all;
  }
  .no-border {
    td {
      border: 1px solid rgba($color-postbg, 0);
    }
  }
}
// used to toggle absence of elements
.show {
  visibility: visible !important;
  @include fade_in;
}

// screen auto-adjustment
@media screen and (max-width: 800px) {
  .page .container {
    width: 100% !important; // to avoid the affect of applyment of toggle_maximize()
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0;
    border: $thick-border;
    background-color: rgba($color-postbg, 0.7);
  }
  .page .container .div_article {
    border: none;
  }
}
@media screen and (max-width: 800px) and (min-width: 400px) {
  .page .container .div_article #main_atcl {
    padding: 12px 17px 17px 17px;
  }  
}
@media screen and (max-width: 400px) {
  .page .container {
    border: $medium-border;
  }
  .page .container .div_article #main_atcl {
    padding: 0px 5px 5px 5px ;
  }
}
