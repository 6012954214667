#disqus_thread {
    margin: 2.5em 0 0 0;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  right: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%;/* Full height */
  background-color:transparent;
  overflow: auto; /* Enable scroll if needed */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

/* Modal Content */
.modal-content {
  position: fixed;
  left:20%;
  top: 10%;
  background-color: transparent;
  width: auto;
  height: auto;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

.modal input {
	background-color:white;
	color:black;
}

.modal button {
	background-color:lightgrey;
	color:black;
}
.modal button:hover {
	background-color:gainsboro;
}

.modal #result {
	border:0;
}

/* The Close Button */
.close {
  padding-right:10px;
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: grey;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  background-color:white; 
  padding: 2px 16px;
} 


/* Add Animation */
@-webkit-keyframes slideIn {
  from {top: -300px; opacity: 0;} 
  to {top: 0; opacity: 0.98;}
}

@keyframes slideIn {
  from {top: -300px; opacity: 0;}
  to {top: 0; opacity: 0.98;}
}

@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 0.98;}
}

@keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 0.98;}
}