.collection_list_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: mix(rgba($color-archive, 0.25), rgba($color-bg, 0.6));
  visibility: hidden;
  z-index: 4;
}
.collection_list_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.collection_list {
  top: 15%;
  left: 13%;
  height: 76%;
  max-height: 650px;
  width: 74%;
  position: absolute;
  z-index: 5;
  margin: 0 auto;
  overflow: none;
  box-sizing: border-box; // interior border
  background-color: rgba($color-postbg, 0.9);
  border: $secondary-border;
  .collection_list_header {
    height: $collection-list-header-size;
    text-align: left;
    padding: 0;
    border-bottom: $secondary-border;
    .collection_list_header_title {
      height: auto;
      width: auto;
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: $collection-list-header-size * 0.65;
      border: 0;
      tbody {
        border: none;
      }
      td {
        height: $collection-list-header-size;
        padding: 0 0 0 0.4em;
        vertical-align: middle;
      }
      #clh_title {
        text-transform: capitalize;
      }
    }
    .close_btn {
      height: $collection-list-header-size;
      width: $collection-list-header-size;
      right: 0;
      display: inline-block;
      position: absolute;
      text-align: center;
      vertical-align: middle;
      border-left: $secondary-border;
      @include simple_link_style;
    }
    .close_btn_icon {
      font-size: $collection-list-header-size * 0.68;
    }
    .search_btn {
      color: $color-warning !important;
    }
  }
  .collection_name_list {
    top: 28px + $collection-list-header-size;
    bottom: 28px;
    width: 25%;
    height: auto;
    position: absolute;
    margin: 0;
    overflow-x: hidden;
    @include thin_scrollbar;
    text-align: end;
    td {
      padding: 0;
    }
    ul {
      margin: 0;
      padding: 0px 10px 0px 26px;
      text-align: left;
    }
    li {
      list-style-type: none;
    }
  }
  .posts_by_cate {
    top: 28px + $collection-list-header-size;
    bottom: 28px;
    left: 25%;
    right: 0;
    width: auto;
    height: auto;
    position: absolute;
    padding: 0 0 0 14px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    border-left: $thin-border;
    td {
      padding: 0;
    }
    ul {
      margin: 0;
      padding: 0px 2.2em 0px 2.2em;
      text-align: left;
    }
    li {
      list-style-type: disc;
    }
    .post_list_in_1_collection {
      position: absolute;
      width: 100%;
      height: 100% !important;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow-x: hidden;
      @include thin_scrollbar;
      visibility: collapse;
    }
  }
  .vertical_center_table {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    bottom: 0;
    vertical-align: middle;
    tbody {
      border: none;
    }
    li {
      width: auto;
    }
  }
  // left part
  .a_collection_label {
    padding: 0;
    font-size: $font-size-h3;
    line-height: 160%;
    text-transform: capitalize;
    padding: 5px 0;
    @include simple_link_style;
  }
  // right part
  .a_title {
    padding: 0;
    font-size: $font-size-h6;
    line-height: 150%;
    @include simple_link_style;
  }
  // labels of "all" collections in right part
  .h_collection_label {
    margin: 0 0 0.5em 0;
    font-size: $font-size-h6;
    text-transform: capitalize;
    border-bottom: $thin-border;
  }
}
