// fonts
@font-face {
    font-family: "Fira Code Online";
    src: url("../fonts/FiraCode-Regular.woff");
  }

// font family
$font-serif: Georgia, Times, serif, "Source Han Sans CN Medium", "PingFang SC",
  "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei" !default;
$font-sans-serif: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
  "Helvetica Neue", "Lucida Grande", Arial, sans-serif, "Source Han Serif CN Medium",
  "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei",
  "WenQuanYi Micro Hei" !default;
$monospace: Monaco, Consolas, "Lucida Console", monospace !default;
$font-monospace: "Fira Code", "Source Code Pro", "Fira Code Online", Monaco,
  Consolas, "Lucida Console", monospace !default;  