// global variables
$color-primary: rgb(84, 175, 24);
$color-archive: rgb(35,75,10);
$color-secondary: rgb(240, 178, 18);
$color-blockquote: #e0ac3c;
$color-warning: rgb(128, 128, 128);
$color-bg: rgb(24, 17, 17);
$color-postbg: rgb(45, 45, 45);
$image-background: none;

// font size
$font-size-h1: 23px;
$font-size-h2: 22px;
$font-size-h3: 22px;
$font-size-h4: 19.5px;
$font-size-h5: 18.5px;
$font-size-h6: 18px;
$font-size-primary: 16px;
$font-smaller: 93%;
$font-bigger: 108%;
$blk-duration: 1.2s;
$blk-timing-function: cubic-bezier(0.98, -0.23, 0.65, 0.7);

// layout reset
$header-height: 37px;
$header-btn-icon-size: 17px;
$header-btn-text-size: $header-btn-icon-size * 0.95;
$toc-position: $header-height + 1px;
$footer-icon-size: 14px;
$footer-text-size: $footer-icon-size * 0.9;
$collection-list-header-size: 30px;
$global-section-margin: 4px;

// standard border
$thick-border: 2.1px solid $color-primary;
$secondary-border: 1.35px solid rgba($color-primary, 0.87);
$medium-border: 0.95px solid rgba($color-primary, 0.83);
$thin-border: 0.5px solid rgba($color-primary, 0.6);

// syntax highlight
$code-primary: $color-primary;
$comment: $color-secondary;
$keyword: #66d4ff;
$type: rgb(195, 233, 25);
$variable: #a159ff;
$number: #4ef9ff;
$string: #ad7f7f;
$punctuation: #549ef3;
$namespace: #8a076d;
$class: rgb(230, 126, 29);
$built-in: #be273b;
$global: #52c96c;
$error: $color-warning;
$code-background: rgb(19, 16, 16);
$error-background: #250f0f;