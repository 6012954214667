// post-list
.post_list {
  .td_title {
    text-transform: capitalize;
    @include simple_link_style;
  }
  .td_date {
    max-width: 100px;
  }
  .td_category {
    max-width: 150px;
  }
}
